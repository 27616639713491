import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Wrapper = styled.div`
  margin: 96px auto 0;

  h2 {
    margin-bottom: 1rem;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2.2rem;
  }

  p {
    font-size: 0.9rem;
    font-weight: 300;
  }

  a {
    text-decoration: none;
    color: brown;
  }
`

const Margins = styled.div`
  margin: 0 auto;
  max-width: 1060px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 0 32px;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: stretch;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    flex-direction: ${props => props.direction || "row"};
  }
`

const TextWrapper = styled.div`
  width: 50%;
  padding-right: 100px;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
    padding: 8px;
    text-align: center;
  }
`

const ImageWrapper = styled.div`
  width: 50vw;
  max-width: ${props => `${props.maxWidth}px` || "auto"};
  flex-shrink: 0;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
    padding-top: 20px 8px;
  }
`

const TitleBlock = styled.div`
  margin-right: 60px;

  h1 {
    margin-bottom: 48px;
    text-decoration: underline;
    font-weight: 800;
    font-size: 3.5rem;
    text-transform: uppercase;
    text-align: left;
  }

  p {
    line-height: 1.5rem;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin-left: 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    margin: 63px 0;
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.small}) {
    h1 {
      font-size: 2.2rem;
      word-break: break-word;
    }
  }
`

const Span = styled.div`
  width: 100%;
`

const ProductDescriptionBlock = styled.div`
  margin-bottom: 20px;
  padding-left: calc(50% - 530px);

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    padding: 16px;
  }
`

const GraySpan = styled(Span)`
  background: ${({ theme }) => theme.palette.lightGrey};
  padding: ${props => (props.pageBreak ? "16px" : "48px")};
  text-align: center;
  margin-top: 16px;

  p {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: ${({ theme }) => theme.palette.darkText};
  }
`

const ImageSpan = styled.div`
  width: 100%;
  display: flex;
  margin: 20px 8px;
  flex-wrap: wrap;
`

const ImageSpanImageWrapper = styled.div`
  width: calc(25% - 20px);
  margin: 8px;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(50% - 20px);
  }
`

const Infobox = styled.div`
  border-style: solid;
  border-color: ${({ theme }) => theme.palette.mainBlue};
  margin-bottom: 25px;
  padding: 30px;
  max-width: 570px;

  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.8rem;
    color: #000;
  }
  ul {
    list-style-type: none;
    font-size: 0.9rem;
    width: 50%;
  }

  ul li::before {
    content: "☑";
  }

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    margin: 25px auto;
  }
`

const InfoboxWrapper = styled.div`
  width: 50%;
  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: 100%;
    padding: 20px;
  }
`

const OuterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const InfoboxFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  ul:first-child {
    padding-left: 0;
  }
`

const UpperBoxWrapper = styled.div`
  display: flex;
  width: 100%;

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    display: initial;
  }
`

const LowerBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TextBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 96px;
  width: 50%;
  flex: 1 1 0%;

  p {
    padding: 20px 0;
    flex: 1 1 0;
  }

  a {
    display: block;
    color: rgb(255, 255, 255);
    background-color: ${({ theme }) => theme.palette.mainBlue};
    width: fit-content;
    cursor: pointer;
    font-weight: 600;
    line-height: 1em;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    padding: 5px;

    :hover {
      filter: drop-shadow(rgba(0, 0, 0, 0.5) 2px 2px 5px);
    }
  }
`

const Box = styled.div`
  display: flex;
  width: calc(50% - 16px);
  margin: 8px;
  padding: 16px;
  background-color: ${({ theme }) => theme.palette.lightGrey};

  @media only screen and (max-width: ${props =>
      props.theme.breakpoints.large}) {
    width: calc(100% - 16px);
  }
`

const LowerBox = styled.div`
  display: flex;
  width: calc(100% - 16px);
  margin: 8px;
  padding: 16px;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.palette.lightGrey};

  .gatsby-image-wrapper {
    width: 239px;
    height: 100%;
    object-fit: contain !important;
    object-position: center center !important;

    @media only screen and (max-width: ${props =>
        props.theme.breakpoints.large}) {
      width: calc(100% - 16px);
    }
  }
`

const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
`

const BoxImageWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 0px 16px;
`

const BottomBoxImageWrapper = styled.div`
  width: 50%;
  margin: 0px 0px 0px 16px;
  display: flex;
  justify-content: center;
`

const BoxComponent = styled.div`
  padding-bottom: 20px;
`

const SingleProductPage = () => (
  <StaticQuery
    query={graphql`
      {
        schwingtore: file(name: { eq: "foto-bgu-08" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        gridimage1: file(name: { eq: "opd" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        gridimage2: file(name: { eq: "variant_pass-door" }) {
          name
          childImageSharp {
            fluid {
              src
              aspectRatio
            }
          }
        }
        allImageSharp(
          filter: {
            fluid: {
              originalName: {
                regex: "/br_gar_uch_055|foto-bgu-06.jpg|foto-bgu-07.jpg|SWT_Weiß.png/"
              }
            }
          }
        ) {
          edges {
            node {
              fluid(maxWidth: 350, maxHeight: 350, cropFocus: CENTER) {
                originalName
                src
                aspectRatio
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Layout>
        <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
        <Wrapper>
          <ProductDescriptionBlock>
            <Flex direction={"column"}>
              <TitleBlock>
                <h1>SCHWINGTORE</h1>
                <p>
Zeitlos schön, zeitlos elegant – wer Frühstück bei Tiffany einem Coffee-to-go im Einweg-Pappbecher vorzieht, der wird unsere Schwingtore lieben. Die elegante Nach-Oben-Schwing-Bewegung gleicht der Umsetzung des Sesam-Öffne-Dich-Prinzips.  Ein bekannter Klassiker auf dem Stand der Zeit.
                  <br />
                  <br />
                  <ul>
                    <li>Kugellagergelagerte Laufschienen für geräuscharmen Torbetrieb</li>
                    <li>Wahlweise in manueller oder automatischer Ausführung erhältlich</li>
                    <li>Bodenschienen aus Edelstahl serienmäßig bei jedem Modell</li>
                    <li>Prominent und Variant Türmodelle sind mit integrierter Nebentür/Schlupftür (nach außen öffnend) erhältlich</li>
                    <li>Zweipunktverriegelung</li>
                    <li>6 Standardfarben mit endbehandelter Oberfläche</li>
                    <li>4 Modellvarianten mit glatter Oberfläche</li>
                    <li>Alle Oberflächen vollständig beschichtet und damit sehr reinigungsfreundlich</li>
                    <li>2 Jahre Garantie auf das Garagentor inklusive aller Verschleißteile.</li>
                  </ul>
                </p>
              </TitleBlock>

              <ImageWrapper>
                <Img
                  fluid={props.schwingtore.childImageSharp.fluid}
                  alt="schwingtore"
                />
              </ImageWrapper>
            </Flex>
          </ProductDescriptionBlock>

          <ImageSpan>
            <ImageSpanImageWrapper>
              <Img
                fluid={props.allImageSharp.edges[0].node.fluid}
                alt="schwingtor"
              />
            </ImageSpanImageWrapper>
            <ImageSpanImageWrapper>
              <Img
                fluid={props.allImageSharp.edges[1].node.fluid}
                alt="schwingtor"
              />
            </ImageSpanImageWrapper>
            <ImageSpanImageWrapper>
              <Img
                fluid={props.allImageSharp.edges[2].node.fluid}
                alt="schwingtor"
              />
            </ImageSpanImageWrapper>
            <ImageSpanImageWrapper>
              <Img
                fluid={props.allImageSharp.edges[3].node.fluid}
                alt="schwingtor"
              />
            </ImageSpanImageWrapper>
          </ImageSpan>

          <Margins>
            <BoxComponent>
              <LowerBoxWrapper>
                <LowerBox>
                  <TextBlock>
                    <Title>Das klappt ja ausgezeichnet</Title>
                    <p>
                      Sind Sie bereit für den großen Auftritt? Dann setzen Sie
                      Ihr Schwingtor in Bewegung, das elegant nach oben klappt.
                      Mit der typischen Schwingbewegung bringt solch ein Kipptor
                      Dynamik und optische Wirkung in Ihr Umfeld. Diesen
                      Klassiker haben wir neuen aufregenden Merkmalen für Sie
                      noch attraktiver gestaltet.
                    </p>
                    <a href="https://normstahl.com/de-de/garagentore/schwingtore" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                </LowerBox>
              </LowerBoxWrapper>
              <UpperBoxWrapper>
                <Box>
                  <TextBlock>
                    <Title>Einfach aber stilvoll</Title>
                    <p>
                      Unglaublich einfach zu bedienen, extrem sicher und
                      langlebig. Die zeitlose Eleganz unserer einteiligen Tore
                      verleiht Ihrer Immobilie ein optisches Highlight!
                    </p>
                    <a href="https://normstahl.com/de-de/garagentore/schwingtore" target="_blank" rel="noopener noreferrer">
                      Mehr erfahren
                    </a>
                  </TextBlock>
                  <BoxImageWrapper>
                    <Img
                      fluid={props.gridimage1.childImageSharp.fluid}
                      alt="OSP42A Industrial Door"
                    />
                  </BoxImageWrapper>
                </Box>
                <Box>
                  <TextBlock>
                    <Title>Zubehör Schwingtore</Title>
                    <p />

                  </TextBlock>
                  <BoxImageWrapper>
                    <Img
                      fluid={props.gridimage2.childImageSharp.fluid}
                      alt="OSP42A Industrial Door"
                    />
                  </BoxImageWrapper>
                </Box>
              </UpperBoxWrapper>
            </BoxComponent>
          </Margins>
        </Wrapper>
      </Layout>
    )}
  />
)

export default SingleProductPage
